<template>
  <div class="overlay">
    <transition
        appear
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut">
      <div
          class="backdrop"
          v-if="state.show" />
    </transition>
    <transition
        appear
        enter-active-class="animated bounceInDown"
        leave-active-class="animated bounceOutUp">
      <div
          class="container"
          v-if="state.show">
        <div class="overlay-content">
          <header>
            <div class="logo">
              <img
                  :src="`${state.basePath}images/${state.product.logo}`"
                  :alt="state.product.title"
                  v-if="state.product.logo">
            </div>
            <h1>
              <span class="brand">{{ state.product.brand }}</span>
              <span class="title">{{ state.product.title }}</span>
            </h1>
            <svg
                viewBox="0 0 825 103.6"
                class="header">
              <polygon class="st0" points="-5.5,31 234.4,96.5 836.4,29.8 836.4,116.3 -5.5,117.4" />
              <polygon class="st1" points="826.8,75.7 836.4,75.7 836.4,9.2 234.4,55.9 -5.5,10.4 -5.5,76.8 7.9,76.8 109,224.3 286,287.3 573,217.3 856,313.3" />
              <polygon class="st1" points="-39.3,218.8 -48.9,218.8 -48.9,285.3 553.1,238.6 793,284.1 793,217.7 779.6,217.7 678.5,70.2 501.5,7.2 214.5,77.2 -68.5,-18.8" />
              <polygon class="st1" points="-90,-2.9 609,93.1 828,45.1 914,58.1 865,167.1 530,173.1 366,210.1 23,71.1" />
            </svg>
          </header>
          <div v-html="state.product.shortDescription" class="lead" />
          <div class="slider">
            <swiper
                :navigation="{
                  nextEl: '.next',
                  prevEl: '.prev',
                  disabledClass: 'hidden'
                }"
                :pagination="{
                  clickable: true
                }"
                :space-between="10">
              <swiper-slide v-if="state.product.image">
                <div class="media-container">
                  <img
                      :src="`${state.basePath}images/${state.product.image}`"
                      :alt="state.product.title">
                </div>
              </swiper-slide>
              <swiper-slide v-if="state.product.ytId">
                <div class="media-container">
                  <iframe
                      :src="`https://www.youtube.com/embed/${state.product.ytId}?modestbranding=1&showinfo=0&showsearch=0&rel=0&iv_load_policy=3&controls=2`"
                      frameborder="0"
                      allowfullscreen />
                </div>
              </swiper-slide>
              <swiper-slide
                  v-for="image in state.product.media"
                  :key="image.title">
                <div class="media-container">
                  <img :src="`${state.basePath}images/${image.title}`">
                </div>
              </swiper-slide>
            </swiper>
            <Prev class="prev" />
            <Next class="next" />
          </div>
          <h2>Beschreibung</h2>
          <div v-html="state.product.description" />
          <h2>USP</h2>
          <div v-html="state.product.usp" />
          <footer>
            <svg
                viewBox="0 0 825 103.6"
                class="footer">
              <polygon class="st0" points="-5.5,31 234.4,96.5 836.4,29.8 836.4,116.3 -5.5,117.4" />
              <polygon class="st1" points="826.8,75.7 836.4,75.7 836.4,9.2 234.4,55.9 -5.5,10.4 -5.5,76.8 7.9,76.8 109,224.3 286,287.3 573,217.3 856,313.3" />
              <polygon class="st1" points="-39.3,218.8 -48.9,218.8 -48.9,285.3 553.1,238.6 793,284.1 793,217.7 779.6,217.7 678.5,70.2 501.5,7.2 214.5,77.2 -68.5,-18.8" />
              <polygon class="st1" points="-90,-2.9 609,93.1 828,45.1 914,58.1 865,167.1 530,173.1 366,210.1 23,71.1" />
            </svg>
            <div class="footer-content">
              <h2>Lust auf weitere Infos?</h2>
              <input
                  type="email"
                  placeholder="Ihre E-Mail-Adresse"
                  v-model="state.email" />
              <br>
              <button
                  class="btn btn-primary"
                  @click="requestInfos()">
                Infos anfordern
              </button>
            </div>
          </footer>
        </div>
        <BackBtn
            @click="close()"
            title="Overlay schließen" />
      </div>
    </transition>
  </div>
</template>


<script>
import { onMounted, getCurrentInstance, reactive } from 'vue'
import { buildFormData } from '@/tools.js'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import BackBtn from '@/components/BackBtn'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  props: {
    id: Number
  },

  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next,
    BackBtn
  },

  setup (props, { emit }) {
    const instance = getCurrentInstance()
    const emitter  = instance.appContext.config.globalProperties.emitter
    const axios    = instance.appContext.config.globalProperties.axios

    const state = reactive({
      publicPath: process.env.BASE_URL,
      basePath: process.env.VUE_APP_API_ENDPOINT,
      show: false,
      product: {},
      email: ''
    })

    const getProduct = async (id) => {
      emitter.emit('loading-start')
      await axios
        .all([
          axios.get(`${process.env.VUE_APP_API_ENDPOINT}products/${id}`),
          axios.get(`${process.env.VUE_APP_API_ENDPOINT}media/${id}`)
        ])
        .then(axios.spread((...responses) => {
          state.product = responses[0].data
          state.product.media = responses[1].data
          emitter.emit('loading-stop')
          state.show = true
      }))
    }

    const close = () => {
      state.show = false
      setTimeout(() => emit('close'), 1000)
    }

    const requestInfos = () => {
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}request-info`, buildFormData({
          sender: state.product.email,
          request:  state.email,
          product: state.product.title
        }))
        .then((response) => {
          if (response.data.sentMail) {
            // feedback
            createToast(
              'Der Hersteller wurde benachrichtigt.',
              { type: 'success', position: 'bottom-right', showIcon: true }
            )
            // reset form
            state.email = ''
          }
        })
        .catch(() => {
          // error
          createToast('Anfrage nicht erfolgreich!',
            { type: 'danger', position: 'bottom-right', showIcon: true }
          )
        })
    }

    onMounted(() => {
      getProduct(props.id)
    })

    return {
      state,
      close,
      requestInfos
    }
  }
}
</script>


<style lang="scss" scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($color: #333, $alpha: 0.85);
}

.container {
  position: absolute;
  width: 80%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep() .overlay-content {
  overflow: auto;
  width: 50rem;
  height: 76%;
  padding: 0 5rem;
  background: #e7e9f1;
  position: relative;
  box-shadow: 0 0 3rem rgba($color: #000, $alpha: 0.75);
  border-radius: 5px;

  header {
    background: #fff;
    margin-left: -5rem;
    margin-right: -5rem;
    padding: 3rem 5rem 6rem 5rem;
    margin-bottom: 3rem;
    position: relative;
    display: flex;

    .logo {
      width: 30%;
      min-width: 120px;
      max-width: 180px;
      margin-top: 0.5rem;
      border-right: 1px solid rgba($color: #333, $alpha: 0.15);
      margin-right: 1.5rem;
      padding-right: 1.5rem;

      img {
        width: 100%;
        display: block;
      }
    }

    h1 {
      margin: 0;
      color: #222;
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.1em;
      position: relative;
      z-index: 10;

      .brand {
        font-size: 0.8rem;
        line-height: 1rem;
        display: inline-block;
        background-color: #ddd;
        padding: 0.35rem 1rem 0.25rem 1rem;
        border-radius: 1rem;
        font-weight: 700;
        margin: 0 0 1rem 0;
      }

      .title {
        display: block;
      }
    }

    svg.header {
      position: absolute;
      left: 0;
      bottom: 0;

      .st0{
        fill: #e7e9f1;
        opacity: 1;
      }

      .st1{
        opacity: 0.25;
        fill: #e7e9f1;
      }
    }
  }

  h2 {
    margin: 3rem 0 1.5rem 0;
    color: #061d86;
    font-weight: 600;
    font-size: 1.75rem;
  }

  .lead p {
    font-size: 1.15rem;
    line-height: 1.6rem;
  }

  p,
  ul {
    margin-bottom: 2rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #5b6e7f !important;

    span {
      color: #5b6e7f !important;
    }
  }

  ul {
    margin-left: 1.25rem;
    li {
      margin: 1.25rem 0;

      &::marker {
        font-size: 1.5rem;
        color: orange;
      }
    }
  }

  .media-container {
    position: relative;
    padding-bottom: calc(56.25% + 0rem);
    padding-top: 0;
    height: 0;
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: -0rem;
    margin-right: -0rem;

    iframe {
      position: absolute;
      top: 0rem;
      left: 0rem;
      width: calc(100% - 0rem);
      height: calc(100% - 0rem);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
      display: block;
    }
  }

  .slider {
    position: relative;
  }

  footer {
    margin-top: 3rem;
    margin-left: -5rem;
    margin-right: -5rem;

    svg.footer {
      display: block;

      .st0 {
        fill: #5b6e7f;
        opacity: 1;
      }

      .st1 {
        opacity: 0.25;
        fill: #5b6e7f;
      }
    }

    .footer-content {
      background: #5b6e7f;
      padding: 2rem 5rem;
      text-align: center;

      h2 {
        margin-top: 0;
        color: #fff;
      }

      input {
        width: 60%;
        padding: 1rem;
        font-size: 1rem;
        font-weight: 600;
      }

      .btn-primary {
        background-color: #ffb413;
        color: #222;
        width: 60%;
        margin: 1rem 0;

        &:hover {
          background-color: #333;
          color: #fff;
        }
      }
    }
  }
}

.bounceInDown {
  animation-delay: 500ms;
}

.fadeOut {
  animation-delay: 750ms;
}

.prev,
.next {
  top: 50%;
  transform: translateY(-50%);
}

.prev {
  left: -5rem;
}

.next {
  right: -5rem;
}

.hidden {
  opacity: 0.25 !important;
  pointer-events: none;
}
</style>
