<template>
  <button>
    <svg viewBox="0 0 256 256">
      <path d="M91.474,33.861l-89.6,89.6c-2.5,2.5-2.5,6.551,0,9.051l89.6,89.6c2.5,2.5,6.551,2.5,9.051,0s2.5-6.742,0-9.242L21.849,134  H249.6c3.535,0,6.4-2.466,6.4-6s-2.865-6-6.4-6H21.849l78.676-78.881c1.25-1.25,1.875-2.993,1.875-4.629s-0.625-3.326-1.875-4.576 C98.024,31.413,93.974,31.361,91.474,33.861z"/>
    </svg>
  </button>
</template>

<style scoped lang="scss">
button {
  position: absolute;
  width: 8vh;
  height: 8vh;
  background: #fff;
  border: none;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  z-index: 10;

  svg {
    margin: 1.75vh;
    fill: #333;
    transform: rotate(180deg);
    transition: all 250ms ease-in-out;
  }

  &:hover {
    background: #333;

    svg {
      fill: #fff;
    }
  }
}
</style>
